import React, { useState } from "react";
import Slider from "react-slick";
import { Modal } from "antd";

import partner1 from "@/assets/img_wc/partner1.png";
import partner2 from "@/assets/img_wc/partner2.png";
import partner3 from "@/assets/img_wc/partner3.png";
import partner4 from "@/assets/img_wc/partner4.png";
import partner5 from "@/assets/img_wc/partner5.png";
import partner6 from "@/assets/img_wc/partner6.png";
import partner7 from "@/assets/img_wc/partner7.png";
import partner8 from "@/assets/img_wc/partner8.png";
import partner9 from "@/assets/img_wc/partner9.png";
import partner10 from "@/assets/img_wc/partner10.png";
import partner11 from "@/assets/img_wc/partner11.png";
import partner12 from "@/assets/img_wc/partner12.png";
import partner13 from "@/assets/img_wc/partner13.png";
import partner14 from "@/assets/img_wc/partner14.png";
import incred from "@/assets/img_wc/icon-incred.png";

import "antd/dist/antd.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import style from "./index.module.scss";

const SERVICE_LIST = [
  {
    title: "Know your Customers and Market",
    imgUrl: "index-tab1.png",
    content: (
      <>
        <p>We help you to evaluate market segments based on your priorities.</p>
        <br />
        <p>
          Our core strengths lie in crafting robust business strategies,
          devising comprehensive market plans and executing effective
          go-to-market strategies.
        </p>
        <br />
        <p>
          At Growhiz, we specialise in crystallising your dominant objectives
          and action plans to achieve them in the most cost-effective manner
          possible.
        </p>
      </>
    ),
  },
  {
    title: "Finding the Right Partnership",
    imgUrl: "index-tab2.png",
    content: (
      <>
        <p>We excel at putting together solutions that accelerate growth.</p>
        <br />
        <p>
          Our commitment to independence and objectivity ensures that we focus
          entirely towards your needs and objectives.
        </p>
        <br />
        <p>
          We offer solutions and foster relationships without any hint of
          conflict or compromise.
        </p>
      </>
    ),
  },
  {
    title: "Realize value at scale",
    imgUrl: "index-tab3.png",
    content: (
      <>
        <p>We'll guide you at every step and make sure things get done.</p>
        <br />
        <p>
          To succeed and stay relevant, you must make your business grow quickly
          and with a clear plan.
        </p>
        <br />
        <p>
          Our digital-only approach is like a roadmap that shows you how to make
          big changes fast. It helps you make the most out of your service
          providers to make your business work better at all levels.
        </p>
      </>
    ),
  },
  {
    title: "Initiate a Swift Business Launch",
    imgUrl: "index-tab4.png",
    content: (
      <>
        <p>
          We give you data and tools to make your strategic vision a reality.
        </p>
        <br />
        <p>We know that not all changes succeed and we understand why!</p>
        <br />
        <p>
          Our work, backed by extensive research and analysis, covers everything
          from saving costs to handling organisational changes. It guides you
          towards achieving the performance and value you want.
        </p>
      </>
    ),
  },
];

const GROWHIZ_LIST = [
  {
    title:
      "Assist enterprises in swiftly establishing their business without the hassle of building a team.",
    imgUrl: "index-ad-tab1.png",
  },
  {
    title:
      "Adjust your team size effortlessly according to market trends, without any hassle or discomfort. ",
    imgUrl: "index-ad-tab2.png",
  },
  {
    title:
      "Say goodbye to severance pay and complicated labour laws when downsizing.",
    imgUrl: "index-ad-tab3.png",
  },
  {
    title:
      "No more missing out on profitable projects due to slow local hiring processes. ",
    imgUrl: "index-ad-tab4.png",
  },
];

const CUSTOMER = [
  {
    content: "“Can quickly help us carry out market cooperation”",
    time: "08-2023",
    name: "Judy",
  },
  {
    content:
      "“Possess professional operation ability, which is of great help to the operation of the enterprise”",
    time: "08-2023",
    name: "Ami",
  },
  {
    content:
      "“The team members are very motivated and very helpful to the business”",
    time: "08-2023",
    name: "Candy",
  },
];

const partner_row1 = [
  { src: partner1, url: "" },
  { src: partner2, url: "" },
  { src: partner3, url: "" },
  { src: partner4, url: "" },
  { src: partner5, url: "https://earlysalary.in/" },
];

const partner_row2 = [
  { src: partner6, url: "" },
  { src: partner7, url: "" },
  { src: partner8, url: "" },
  { src: partner9, url: "https://bhanixfinance.com/ourpartners.html" },
  {
    src: incred,
    // style: { width: "120px" },
    url: "https://www.incred.com/home/",
  },
];

const partner_row3 = [
  { src: partner10, url: "" },
  { src: partner11, url: "" },
  { src: partner12, url: "" },
  { src: partner13, url: "" },
  { src: partner14, url: "" },
];

const sliderSettings = {
  dots: true,
  // autoplay: true,
  infinite: true,
  speed: 500,
  slidesToScroll: 1,
  slidesToShow: 2.999,
};

const Home = () => {
  const [optinon, setOption] = useState(0);

  const handleSliderClick = (index) => {
    setOption(index);
  };

  return (
    <div>
      <div id="tab1" className={style.banner}>
        <img
          width="100%"
          src={require("@/assets/img_wc/index-banner.png")}
          alt="Banner"
        />
      </div>

      <div
        className={style.ourBusiness}
        style={{
          background: `url(${require("@/assets/img_wc/index-gp-bg.png")}) no-repeat center/100% 100%`,
        }}
      >
        {/* <a className={style.downloadBtn} href='https://play.google.com/store/apps/details?id=com.finshell.fin' target="" >Download the App</a> */}
        <div
          className={style.downloadBtn}
          onClick={() =>
            (window.location.href =
              "https://play.google.com/store/apps/details?id=com.finshell.fin")
          }
        ></div>
      </div>

      <div className={style.ourService}>
        <div className={style.textContainer}>
          <div className={style.title}>Our service</div>
          <div className={style.contentBox}>
            <div className={style.content}>{SERVICE_LIST[optinon].content}</div>
          </div>
          <div className={style.footer}>
            {SERVICE_LIST.map((_, index) => {
              return (
                <div
                  key={index}
                  className={`${style.slider} ${
                    optinon === index && style.active
                  }`}
                  onClick={() => handleSliderClick(index)}
                ></div>
              );
            })}
          </div>
        </div>
        <div className={style.imageGrid}>
          {SERVICE_LIST.map((item, index) => {
            return (
              <div
                key={index}
                className={style.image}
                style={{
                  background: `url(${require("@/assets/img_wc/" +
                    item.imgUrl)}) no-repeat center/100% 100%`,
                }}
                onClick={() => handleSliderClick(index)}
              >
                {item.title}
              </div>
            );
          })}
        </div>
      </div>

      <div
        className={style.features}
        style={{
          background: `url(${require("@/assets/img_wc/bg-img.png")}) no-repeat center/100% 100%`,
        }}
      ></div>

      <div
        className={style.whyFinshellPay}
        style={{
          background: `url(${require("@/assets/img_wc/why.png")}) no-repeat center/100% 100%`,
        }}
      ></div>

      <div className={style.partner}>
        <h1 className={style.title}>Our Partners</h1>
        <div className={style.imgBox}>
          <div>
            {partner_row1.map((item, index) => {
              return (
                <img
                  key={index}
                  src={item.src}
                  alt="Partner"
                  onClick={
                    item.url
                      ? () => (window.location.href = item.url)
                      : () => {}
                  }
                />
              );
            })}
          </div>
          <div>
            {partner_row2.map((item, index) => {
              return (
                <img
                  key={index}
                  src={item.src}
                  style={item?.style && item.style}
                  alt="Partner"
                  onClick={
                    item.url
                      ? () => (window.location.href = item.url)
                      : () => {
                          if (item.showDialog) {
                            Modal.info({
                              icon: "",
                              width: 800,
                              title: item.title,
                              content: item.content,
                            });
                          }
                        }
                  }
                />
              );
            })}
          </div>
          <div>
            {partner_row3.map((item, index) => {
              return (
                <img
                  key={index}
                  src={item.src}
                  alt="Partner"
                  onClick={
                    item.url
                      ? () => (window.location.href = item.url)
                      : () => {}
                  }
                />
              );
            })}
          </div>
        </div>
      </div>

      <div className={style.growhiz}>
        <h1 className={style.title}>Growhiz Advantage</h1>
        <h2 className={style.content}>
          Efficiently and quickly assist your business
        </h2>
        <div className={style.infoCard}>
          {GROWHIZ_LIST.map((item, index) => {
            return (
              <div className={style.item} key={index}>
                <img
                  height={"100%"}
                  src={`${require("@/assets/img_wc/" + item.imgUrl)}`}
                  alt="Infomation Card"
                />
                <div className={style.title}>{item.title}</div>
              </div>
            );
          })}
        </div>
      </div>

      <div
        className={style.customerSay}
        style={{
          background: `url(${require("@/assets/img_wc/index-comment.png")}) no-repeat center/100% 100%`,
        }}
      >
        <h1 className={style.title}>What Customers Say</h1>
        <div className={style.sliderBox}>
          <Slider
            {...sliderSettings}
            // ref={(slider) => {
            //   console.log(slider, "slider");
            // }}
          >
            {CUSTOMER.map((item, index) => {
              return (
                <div key={index} className={style.slider}>
                  <div className={style.item}>
                    <div className={style.stars}>
                      <img
                        src={require("@/assets/img_wc/icon-star.png")}
                        alt="stars"
                      />
                      <img
                        src={require("@/assets/img_wc/icon-star.png")}
                        alt="stars"
                      />
                      <img
                        src={require("@/assets/img_wc/icon-star.png")}
                        alt="stars"
                      />
                      <img
                        src={require("@/assets/img_wc/icon-star.png")}
                        alt="stars"
                      />
                    </div>
                    <div className={style.content}>{item.content}</div>
                    <div className={style.userInfo}>
                      <div className={style.avatar} />
                      <div className={style.user}>
                        <div className={style.name}>{item.name}</div>
                        <div className={style.time}>{item.time}</div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default Home;
