import React from "react";
import style from "./index.module.scss";

export default class footer extends React.Component {
  render() {
    return (
      <div className={style.footer}>
        <img
          className={style.logo}
          src={require("@/assets/img_wc/logo.png")}
          alt="Logo"
        />
        <div className={style.content}>
          <a 
            className={style.policy}
            target="_blank" 
            href="https://leading.finshellpay.com/superior/PrivacyPolicy3881.html" 
          >
            Privacy Policy
          </a>
          <p className={style.copyright}>
            copyright © 2020-23 Growhiz Technology Private Limited.
          </p>
        </div>
      </div>
    );
  }
}
